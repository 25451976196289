@import "../../../resources/styles/media";
@import "../../../resources/styles/colors.less";

.frame {
    position: fixed;
    display: flex;
    flex-direction: column;
}

.commonFrame {
    max-height: 100%; // Needed for dialogs with overflow.
    overflow-y: auto; // This is needed to support IE11 when using a max-height (apparently).
    border-radius: 4px;
}

.wrapper {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    @media (max-width: @screen-sm) {
        align-items: inherit;
    }
}

.opaque {
    background: @paper1;
}

.padded {
    padding: 1em;
}

.fullscreen {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
}

.shadow {
    box-shadow: @shadowLarge;
}

.huge {
    top: 5%;
    left: 5%;
    bottom: 5%;
    right: 5%;
    box-shadow: @shadowLarge;
    @media (max-width: @screen-sm) {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.medium {
    display: flex;
    flex-direction: column;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    width: 75%;
    max-width: 1000px;

    @media (max-width: @screen-md) {
        width: 100%;
    }
}

.small {
    max-width: 600px;
}

.extraSmall {
    max-width: 450px;
}
